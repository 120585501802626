import { FC } from 'react'

import { captureException } from '@sentry/browser'
import {
  EditClientById,
  TableName,
  UpdateClientMutation,
  UpdateClientMutationVariables,
  UpdateClientStatusMutation,
  UpdateClientStatusMutationVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import ClientForm from 'src/components/SuperAdmin/Client/ClientForm'
import useACL from 'src/lib/hooks/ACL/useACL'

export const QUERY = gql`
  query EditClientById($id: Int!) {
    hubClient: client(id: $id) {
      id
      name
      status
      stripeDefaultProductId
      mfaSetting
    }
    products: productFeaturesBoard {
      id
      name
    }
  }
`
export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClientMutation($id: Int!, $input: UpdateClientInput!) {
    updateClient(id: $id, input: $input) {
      id
      name
    }
  }
`

const UPDATE_CLIENT_STATUS_MUTATION = gql`
  mutation UpdateClientStatusMutation($id: Int!, $status: ClientStatus!) {
    updateClientStatus(id: $id, status: $status) {
      id
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error.message}</div>
)

export type HubClientType = EditClientById['hubClient']

export const Success: FC<CellSuccessProps<EditClientById>> = ({
  hubClient,
  products,
}) => {
  const [updateClientStatus] = useMutation<
    UpdateClientStatusMutation,
    UpdateClientStatusMutationVariables
  >(UPDATE_CLIENT_STATUS_MUTATION)
  const [updateClient, { loading, error }] = useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UPDATE_CLIENT_MUTATION)

  const { AccessControlList, saveACL } = useACL({
    resourceType: 'Client' as TableName,
    resourceId: hubClient.id,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const onSave = async (input: HubClientType) => {
    const { status, ...rest } = input
    const castInput = {
      ...rest,
    }

    try {
      await Promise.all([
        saveACL(),
        updateClient({ variables: { id: hubClient.id, input: castInput } }),
        updateClientStatus({ variables: { id: hubClient.id, status } }),
      ])

      toast.success('Client updated')
      navigate(routes.clients())
    } catch (error) {
      // eslint-disable-next-line quotes
      toast.error("Couldn't update client")
      captureException(error)
    }
  }

  return (
    <div className="rw-segment">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">
          Edit Client {hubClient.id}
        </h2>
      </header>
      <div className="rw-segment-main">
        <ClientForm
          products={products}
          client={hubClient}
          onSave={onSave}
          error={error}
          loading={loading}
        >
          <AccessControlList />
        </ClientForm>
      </div>
    </div>
  )
}
